import { useWorkspaceState } from "@/coordinator/workspace/state/WorkspaceContext";
import { Button } from "@/infrastructure/uikit/components/ui/button";
import { Card, CardDescription, CardHeader, CardTitle } from "@/infrastructure/uikit/components/ui/card";
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from "@/infrastructure/uikit/components/ui/drawer";
import { Input } from "@/infrastructure/uikit/components/ui/input";
import { ScrollArea } from "@/infrastructure/uikit/components/ui/scroll-area";
import { Tabs, TabsList, TabsTrigger } from "@/infrastructure/uikit/components/ui/tabs";
import { ArrowLeft, Box, Search, X } from "lucide-react";
import React, { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IntegrationModel } from "../domain/model/integrationModel";
import { IntegrationDetailView } from "./IntegrationDetailView";

export const IntegrationView: FC = () => {
  const navigate = useNavigate();

  const { frameViewModel, integrationViewModel } = useWorkspaceState();

  const [kind, setKind] = useState("ALL");
  const [search] = useState(integrationViewModel.uiState.searchQuery);

  useEffect(() => {
    integrationViewModel.uiAction.getIntegrations(
      frameViewModel.uiState.scaffold?.organizationId ?? "",
      frameViewModel.uiState.scaffold?.projectPlatform ?? "",
      kind
    );
  }, [kind]);

  return (
    <div className="flex flex-col w-full bg-background h-full">
      <div className="flex flex-row w-full justify-between items-center h-16 bg-card shadow-md px-4">
        <div className="flex flex-row items-center space-x-2">
          <Button
            onClick={() => {
              navigate(-1);
              integrationViewModel.uiAction.search("");
            }}
            variant="ghost"
            size="icon"
            className="rounded-full"
          >
            <ArrowLeft className="h-4 w-4" />
          </Button>
          <p>Integrations marketplace</p>
        </div>
      </div>
      <ScrollArea className="flex flex-col w-full h-full p-4">
        <div className="flex flex-row p-1 space-x-4">
          <div>
            <Tabs
              defaultValue={kind}
              className="flex flex-row justify-center"
              onValueChange={(value: string) => {
                setKind(value);
              }}
            >
              <TabsList>
                <TabsTrigger value="ALL" className="w-20">
                  All
                </TabsTrigger>
                <TabsTrigger value="BLOCK" className="w-20">
                  Blocks
                </TabsTrigger>
                <TabsTrigger value="ACTION" className="w-20">
                  Actions
                </TabsTrigger>
              </TabsList>
            </Tabs>
          </div>
          <div>
            <div className="relative flex items-center max-w-2xl ">
              <Search className="absolute left-2 top-1/2 h-4 w-4 -translate-y-1/2 transform" />
              <Input
                defaultValue={search}
                type="text"
                autoComplete="off"
                placeholder="Integration name"
                className="w-96 pl-8"
                onChange={(event: any) => {
                  integrationViewModel.uiAction.search(event.target.value);
                }}
              />
            </div>
          </div>
        </div>
        <div className="py-4">
          <div className="grid grid-cols-4 gap-4">
            {integrationViewModel.uiState.integrations?.map((integration: IntegrationModel) => {
              return (
                <Drawer key={integration.id} dismissible={false}>
                  <DrawerTrigger asChild>
                    <Card>
                      <CardHeader>
                        <CardTitle className={integration.deprecated ? `line-through` : ``}>
                          <Box className="h-8 w-8 my-4" />
                          {integration.name}
                        </CardTitle>
                        <CardDescription>{integration.description}</CardDescription>
                      </CardHeader>
                    </Card>
                  </DrawerTrigger>
                  <DrawerContent className="h-screen">
                    <DrawerHeader>
                      <div className="flex flex-row justify-between items-center">
                        <DrawerTitle>{integration.name}</DrawerTitle>
                        <DrawerClose>
                          <Button variant={"ghost"} size={"icon"}>
                            <X />
                          </Button>
                        </DrawerClose>
                      </div>
                      <IntegrationDetailView
                        integrationViewModel={integrationViewModel}
                        organizationId={frameViewModel.uiState.scaffold?.organizationId ?? ""}
                        projectId={frameViewModel.uiState.scaffold?.projectId ?? ""}
                        integrationId={integration.id}
                      />
                    </DrawerHeader>
                  </DrawerContent>
                </Drawer>
              );
            })}
          </div>
        </div>
      </ScrollArea>
    </div>
  );
};
