import { LeftSidePanel } from "@/coordinator/workspace/panel/LeftSidePanel";
import { RightSidePanel } from "@/coordinator/workspace/panel/RightSidePanel";
import { PreviewView } from "@/coordinator/workspace/preview/PreviewView";
import { usePreviewViewModel } from "@/coordinator/workspace/preview/PreviewViewModel";
import { useWorkspaceViewModel } from "@/coordinator/workspace/WorkspaceViewModel";
import { ActionView } from "@/feature/action/presentation/ActionView";
import { useActionViewModel } from "@/feature/action/presentation/ActionViewModel";
import { useBlockViewModel } from "@/feature/block/presentation/BlockViewModel";
import { useFrameViewModel } from "@/feature/frame/presentation/FrameViewModel";
import { IntegrationView } from "@/feature/integration/presentation/IntegrationView";
import { useIntegrationViewModel } from "@/feature/integration/presentation/IntegrationViewModel";
import { LocalizationView } from "@/feature/localization/presentation/LocalizationView";
import { useLocalizationViewModel } from "@/feature/localization/presentation/LocalizationViewModel";
import { VariableView } from "@/feature/variable/presentation/VariableView";
import { useVariableViewModel } from "@/feature/variable/presentation/VariableViewModel";
import { Alert, AlertDescription } from "@/infrastructure/uikit/components/ui/alert";
import { Button } from "@/infrastructure/uikit/components/ui/button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/infrastructure/uikit/components/ui/dialog";
import { Input } from "@/infrastructure/uikit/components/ui/input";
import { Label } from "@/infrastructure/uikit/components/ui/label";
import { ResizableHandle, ResizablePanel, ResizablePanelGroup } from "@/infrastructure/uikit/components/ui/resizable";
import { ArrowLeft } from "lucide-react";
import React, { FC, useEffect, useState } from "react";
import { useNavigate, useRoutes } from "react-router-dom";
import { WorkspaceSetting } from "./setting/WorkspaceSetting";
import { useWorkspaceState, WorkspaceState, WorkspaceStateProvider } from "./state/WorkspaceContext";

// let interval: any = undefined;

// const [running, setRunning] = useState(true);
// const [progress, setProgress] = useState(0);
// useEffect(() => {
//   if (running) {
//     interval = setInterval(() => {
//       setProgress((prev) => prev + 2);
//     }, 80);
//   } else {
//     clearInterval(interval);
//   }
// }, [running]);

// useEffect(() => {
//   if (progress === 100) {
//     setRunning(false);
//     clearInterval(interval);
//   }
// }, [progress]);

// useEffect(() => {
//   workspaceViewModel.uiAction.getQuote();
// }, []);

// if (progress < 100) {
//   return (
//     <div className="flex flex-col w-full bg-background h-full items-center justify-center">
//       <blockquote className="space-y-8 space-x-2 p-4 w-1/4">
//         <p className="text-xl">&ldquo;{workspaceViewModel.uiState.quote?.quote}&rdquo;</p>
//         <footer className="text-sm">{workspaceViewModel.uiState.quote?.author}</footer>
//       </blockquote>
//       <Progress value={progress} className="w-1/4 h-1" />
//     </div>
//   );
// }

const WorkspaceRouter: FC = () => {
  return useRoutes([
    ...[
      {
        path: "",
        element: <WorkspaceHome />,
      },
      {
        path: "setting",
        element: <WorkspaceSetting />,
      },
      {
        path: "localization",
        element: <LocalizationView />,
      },
      {
        path: "integration",
        element: <IntegrationView />,
      },
      {
        path: "variable",
        element: <VariableView />,
      },
      {
        path: "action",
        element: <ActionView />,
      },
    ],
  ]);
};

export const WorkspaceView: FC = () => {
  const workspaceViewModel = useWorkspaceViewModel();
  const frameViewModel = useFrameViewModel();
  const blockViewModel = useBlockViewModel();
  const variableViewModel = useVariableViewModel();
  const integrationViewModel = useIntegrationViewModel();
  const actionViewModel = useActionViewModel();
  const localizationViewModel = useLocalizationViewModel();
  const previewViewModel = usePreviewViewModel();

  const state: WorkspaceState = {
    workspaceViewModel,
    frameViewModel,
    blockViewModel,
    variableViewModel,
    integrationViewModel,
    actionViewModel,
    localizationViewModel,
    previewViewModel,
  };

  useEffect(() => {
    integrationViewModel.uiAction.getIntegrationsInstalled(
      frameViewModel.uiState.scaffold?.organizationId ?? "",
      frameViewModel.uiState.scaffold?.projectId ?? ""
    );
    workspaceViewModel.uiAction.selectLeftPanel("FRAME");
  }, [frameViewModel.uiState.scaffold]);

  useEffect(() => {
    previewViewModel.uiAction.setProjectPlatform(
      frameViewModel.uiState.scaffold?.projectId ?? "",
      frameViewModel.uiState.scaffold?.projectPlatform ?? ""
    );
  }, [frameViewModel.uiState.scaffold]);

  useEffect(() => {
    const frameRoute = frameViewModel.uiState.selectedFrame?.route ?? "";
    previewViewModel.uiAction.handleHotReload(frameRoute);
  }, [blockViewModel.uiState.hotReload]);

  return (
    <WorkspaceStateProvider value={state}>
      <WorkspaceRouter />
    </WorkspaceStateProvider>
  );
};

const WorkspaceHome: FC = () => {
  const navigate = useNavigate();
  const [publishFrameName, setPublishFrameName] = useState("");

  const {
    blockViewModel,
    frameViewModel,
    integrationViewModel,
    localizationViewModel,
    actionViewModel,
    variableViewModel,
    workspaceViewModel,
    previewViewModel,
  } = useWorkspaceState();
  return (
    <div className="flex flex-col w-full bg-background h-full">
      <div className="flex flex-row w-full justify-between items-center h-16 bg-card shadow-md px-4">
        <div className="flex flex-row items-center space-x-2">
          <Button
            onClick={() => {
              navigate(-1);
            }}
            variant="ghost"
            size="icon"
            className="rounded-full"
          >
            <ArrowLeft className="h-4 w-4" />
          </Button>
          <h1>{frameViewModel.uiState.scaffold?.projectName}</h1>
        </div>
        <div>
          {frameViewModel.uiState.selectedFrame?.id ? (
            <Dialog>
              <DialogTrigger asChild={true}>
                <Button variant="default">Publish {frameViewModel.uiState.selectedFrame?.name}</Button>
              </DialogTrigger>
              <DialogContent>
                <DialogHeader>
                  <DialogTitle>
                    <span>{`Are you sure to publish '${frameViewModel.uiState.selectedFrame?.name}' frame?`}</span>
                  </DialogTitle>
                  <DialogDescription>
                    <div className="flex flex-col space-y-4 pt-4">
                      <div className="flex flex-col gap-2">
                        <Alert variant="destructive">
                          <AlertDescription>
                            Please note that publishing the frame will update all blocks, variables and actions for all,
                            users. To confirm the publishing please type the name of the frame. This action cannot be
                            undone. It's recommended to use this action only for production updates.
                          </AlertDescription>
                        </Alert>
                      </div>
                      <Label className="sr-only" htmlFor="name">
                        Frame name
                      </Label>
                      <Input
                        id="name"
                        placeholder="name"
                        type="text"
                        autoCapitalize="none"
                        autoComplete="off"
                        autoCorrect="off"
                        onChange={(event: any) => setPublishFrameName(event.target.value)}
                      />
                    </div>
                  </DialogDescription>
                </DialogHeader>
                <DialogClose>
                  <Button
                    onClick={() => {
                      frameViewModel.uiAction.publishFrame(
                        frameViewModel.uiState.selectedFrame?.name ?? "",
                        frameViewModel.uiState.selectedFrame?.route ?? ""
                      );
                    }}
                    disabled={frameViewModel.uiState.selectedFrame?.name !== publishFrameName}
                    className="w-full"
                  >
                    Publish
                  </Button>
                </DialogClose>
              </DialogContent>
            </Dialog>
          ) : (
            <></>
          )}
        </div>
      </div>
      <ResizablePanelGroup direction="horizontal">
        <ResizablePanel className="flex flex-row p-2 space-x-2" defaultSize={20}>
          <LeftSidePanel
            workspaceViewModel={workspaceViewModel}
            frameViewModel={frameViewModel}
            blockViewModel={blockViewModel}
            variableViewModel={variableViewModel}
            integrationViewModel={integrationViewModel}
            localizationViewModel={localizationViewModel}
          />
        </ResizablePanel>
        <ResizableHandle withHandle />
        <ResizablePanel defaultSize={60}>
          <PreviewView previewViewModel={previewViewModel} />
        </ResizablePanel>
        <ResizableHandle withHandle />
        <ResizablePanel className="flex flex-row p-2 space-x-2" defaultSize={20}>
          <RightSidePanel
            workspaceViewModel={workspaceViewModel}
            frameViewModel={frameViewModel}
            blockViewModel={blockViewModel}
            variableViewModel={variableViewModel}
            actionViewModel={actionViewModel}
            integrationViewModel={integrationViewModel}
          />
        </ResizablePanel>
      </ResizablePanelGroup>
    </div>
  );
};
