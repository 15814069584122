import { gql } from "@apollo/client";

export const INTEGRATIONS_QUERY = gql`
  query integrations($organizationId: String!, $kind: String!, $platformSupport: String!, $page: Int!, $limit: Int!) {
    integrations(
      organizationId: $organizationId
      kind: $kind
      platformSupport: $platformSupport
      page: $page
      limit: $limit
    ) {
      id
      keyType
      name
      imageIcon
      price
      version
      description
      platformSupport
      kind
      public
      manageable
    }
  }
`;

export const INTEGRATIONS_INSTALLED_QUERY = gql`
  query integrationsInstalled($organizationId: String!, $projectId: String!, $kind: String!) {
    integrationsInstalled(organizationId: $organizationId, projectId: $projectId, kind: $kind) {
      id
      integrationKeyType
      integrationName
      integrationVersion
      integrationDeprecated
      integrationDeprecatedReason
      integrationImageIcon
      integrationId
      projectId
      hasUpdate
    }
  }
`;
export const INTEGRATION_QUERY = gql`
  query integration($organizationId: String!, $integrationId: String!) {
    integration(organizationId: $organizationId, integrationId: $integrationId) {
      id
      keyType
      name
      imageIcon
      price
      version
      description
      deprecated
      deprecatedReason
      platformSupport
      kind
      public
      documentation
      manageable
      properties {
        id
        key
        type
        value
        description
        deprecated
        deprecatedReason
        valuePicker
        valuePickerGroup
        valuePickerOptions
      }
      data {
        id
        key
        type
        deprecated
        deprecatedReason
      }
      events {
        id
        event
        deprecated
        deprecatedReason
      }
      events {
        id
        event
        deprecated
        deprecatedReason
      }
      slots {
        id
        slot
        deprecated
        deprecatedReason
      }
    }
  }
`;
export const CHECK_INTEGRATION_QUERY = gql`
  query integrationCheckInstallation($organizationId: String!, $projectId: String!, $integrationId: String!) {
    integrationCheckInstallation(
      organizationId: $organizationId
      projectId: $projectId
      integrationId: $integrationId
    ) {
      id
      integrationKeyType
      integrationName
      integrationVersion
      integrationDeprecated
      integrationDeprecatedReason
      integrationId
      projectId
      hasUpdate
    }
  }
`;
export const UNINSTALL_INTEGRATION_MUTATION = gql`
  mutation unInstallIntegration($input: UnInstallIntegrationInput!) {
    unInstallIntegration(input: $input) {
      id
    }
  }
`;
export const INSTALL_INTEGRATION_MUTATION = gql`
  mutation installIntegration($input: InstallIntegrationInput!) {
    installIntegration(input: $input) {
      id
    }
  }
`;
export const UPGRADE_INTEGRATION_MUTATION = gql`
  mutation upgradeIntegration($input: UpgradeIntegrationInput!) {
    upgradeIntegration(input: $input) {
      id
    }
  }
`;
