import { Button } from "@/infrastructure/uikit/components/ui/button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/infrastructure/uikit/components/ui/dialog";
import { Input } from "@/infrastructure/uikit/components/ui/input";
import { Label } from "@/infrastructure/uikit/components/ui/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/infrastructure/uikit/components/ui/select";
import { Textarea } from "@/infrastructure/uikit/components/ui/textarea";
import React, { FC, useState } from "react";
import { VariableTypeModel } from "../domain/model/VariableModel";
import { VariableViewModel } from "./VariableViewModel";

export type VariableCreateViewProps = {
  frameId: string;
  variableViewModel: VariableViewModel;
};

export const VariableCreateView: FC<VariableCreateViewProps> = (props: VariableCreateViewProps) => {
  const [key, setKey] = useState("");
  const [value, setValue] = useState("");
  const [type, setType] = useState("");
  return (
    <Dialog>
      <DialogTrigger asChild={true}>
        <Button variant={"default"} className="">
          Add a new variable
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle asChild>
            <span>Add a new variable</span>
          </DialogTitle>
          <DialogDescription>
            <div className="flex flex-col space-y-4 pt-4">
              <div className="flex flex-col gap-2">
                <Label className="sr-only" htmlFor="key">
                  key
                </Label>
                <Input
                  id="key"
                  placeholder="Key"
                  type="text"
                  autoCapitalize="none"
                  autoComplete="off"
                  autoCorrect="off"
                  onChange={(event: any) => setKey(event.target.value)}
                />
                <Textarea
                  placeholder="Value"
                  onChange={(event: any) => setValue(event.target.value)}
                />
                <Select
                  onValueChange={(value: string) => {
                    setType(value);
                  }}
                >
                  <SelectTrigger className="w-full">
                    <SelectValue placeholder={"Select variable type"} />
                  </SelectTrigger>
                  <SelectContent>
                    {props.variableViewModel.uiState.variableTypes?.map((variableType: VariableTypeModel) => {
                      return (
                        <SelectItem key={variableType.id} value={variableType.id}>
                          <div className="flex flex-row">{variableType.text}</div>
                        </SelectItem>
                      );
                    })}
                  </SelectContent>
                </Select>
              </div>
            </div>
          </DialogDescription>
        </DialogHeader>
        <DialogFooter>
          <DialogClose asChild>
            <Button
              onClick={() => {
                props.variableViewModel.uiAction.addVariable(props.frameId, key, value, type);
                setKey("");
                setValue("");
                setType("");
              }}
              variant={"default"}
              className="w-full"
            >
              Add
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
