import { DevicePreviewView } from "@/coordinator/workspace/preview/DevicePreviewView";
import { AppEnvironmentConfig } from "@/infrastructure/network/EnvironmentProvider";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/infrastructure/uikit/components/ui/select";
import { Tabs, TabsList, TabsTrigger } from "@/infrastructure/uikit/components/ui/tabs";
import { useToast } from "@/infrastructure/uikit/components/ui/use-toast";
import AgoraRTC, { AgoraRTCProvider, IAgoraRTCRemoteUser, RemoteUser, useRTCClient } from "agora-rtc-react";
import { Laptop2, Smartphone, Tablet } from "lucide-react";
import React, { FC, useEffect, useState } from "react";
import QRCode from "react-qr-code";
import { PreviewViewModel } from "./PreviewViewModel";

type PreviewViewProps = {
  previewViewModel: PreviewViewModel;
};

export const PreviewView: FC<PreviewViewProps> = (props: PreviewViewProps) => {
  const { toast } = useToast();
  const agoraClient = useRTCClient(
    AgoraRTC.createClient({
      codec: "vp8",
      mode: "live",
      role: "audience",
    })
  );

  const [remoteUsers, setRemoteUsers] = useState<IAgoraRTCRemoteUser[]>([]);

  useEffect(() => {
    props.previewViewModel.uiAction.handleRemoteUsersChange(remoteUsers);
  }, [remoteUsers]);

  useEffect(() => {
    props.previewViewModel.uiAction.handleAgoraClientChange(agoraClient);
  }, [agoraClient]);

  useEffect(() => {
    setRemoteUsers(() => [...agoraClient.remoteUsers]);
  }, [props.previewViewModel.uiState.selectedTarget.uuid]);

  agoraClient.on("exception", (event) => {
    toast({ description: (event.msg ?? "") + " " + (event.code ?? ""), variant: "destructive" });
  });

  agoraClient.on("user-published", async (user: IAgoraRTCRemoteUser, mediaType: string) => {
    try {
      await agoraClient.subscribe(user, "video");
      setRemoteUsers((prevUsers) => [...prevUsers, user]);
    } catch (err) {
      console.error(`PreviewViewLog Failed to subscribe to user ${user.uid}'s ${mediaType} stream: ${err}`);
    }
  });

  agoraClient.on("user-unpublished", (user: IAgoraRTCRemoteUser) => {
    setRemoteUsers((prevUsers) => prevUsers.filter((u) => u.uid !== user.uid));
  });

  return (
    <>
      {props.previewViewModel.uiState.platform === "REACT" ? (
        <div className={"pt-4"}>
          <Tabs
            className="flex flex-row justify-center"
            onValueChange={(value: string) => {
              props.previewViewModel.uiAction.setDevice(value);
            }}
            value={props.previewViewModel.uiState.device ?? "MOBILE"}
          >
            <TabsList>
              <TabsTrigger value="MOBILE" className="w-20">
                <Smartphone className={"w-4 h-4"} />
              </TabsTrigger>
              <TabsTrigger value="TABLET" className="w-20">
                <Tablet className={"w-4 h-4"} />
              </TabsTrigger>
              <TabsTrigger value="DESKTOP" className="w-20">
                <Laptop2 className={"w-4 h-4"} />
              </TabsTrigger>
            </TabsList>
          </Tabs>
        </div>
      ) : (
        <></>
      )}
      <div className={"flex flex-col justify-center w-full h-full items-center pb-8"}>
        <div className={"pb-2"}>
          {props.previewViewModel.uiState.selectedTarget.uuid == "" ? (
            <div></div>
          ) : (
            <Select
              value={props.previewViewModel.uiState.selectedTarget.uuid}
              onValueChange={(value) => props.previewViewModel.uiAction.selectTarget(value)}
            >
              <SelectTrigger className="w-[180px]">
                <SelectValue placeholder="Select a target" />
              </SelectTrigger>
              <SelectContent>
                {props.previewViewModel.uiState.targets.map((target) => (
                  <SelectItem key={target.uuid} value={target.uuid}>
                    {target.deviceName}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          )}
        </div>
        <DevicePreviewView
          platform={props.previewViewModel.uiState.platform ?? ""}
          device={props.previewViewModel.uiState.device ?? "NONE"}
          videoWidth={props.previewViewModel.uiState.selectedTarget.videoWidth}
          videoHeight={props.previewViewModel.uiState.selectedTarget.videoHeight}
        >
          {props.previewViewModel.uiState.platform == "REACT" ? (
            <div>
              {props.previewViewModel.uiState.previewUrl ? (
                <iframe className="w-full h-full" src={props.previewViewModel.uiState.previewUrl}></iframe>
              ) : (
                <span className="w-full h-full flex flex-row justify-center items-center text-center">
                  Preview is not available
                  <br /> you can enable it from setting
                </span>
              )}
            </div>
          ) : (
            <div>
              {props.previewViewModel.uiState.selectedTarget.uuid == "" ? (
                <div>
                  <span className="w-full h-full flex flex-col justify-center items-center text-center gap-8">
                    <p>
                      Preview is not available
                      <br /> Please scan QR by Wandkit SDK
                    </p>
                    <QRCode
                      value={JSON.stringify({
                        endpoint: AppEnvironmentConfig.API_ENDPOINT_GRAPHQL_URL,
                        token: props.previewViewModel.uiState.authToken,
                        realtimeEndpoint: AppEnvironmentConfig.API_REALTIME_ENDPOINT_URL,
                      })}
                    />
                  </span>
                </div>
              ) : (
                <AgoraRTCProvider client={agoraClient}>
                  {remoteUsers.length ? (
                    <>
                      {remoteUsers.map((user) => (
                        <RemoteUser key={user.uid} user={user} />
                      ))}
                    </>
                  ) : (
                    <span className="w-full h-full flex flex-row justify-center items-center text-center">
                      Preview is not available
                      <br /> you can enable it from liveKit
                    </span>
                  )}
                </AgoraRTCProvider>
              )}
            </div>
          )}
        </DevicePreviewView>
      </div>
    </>
  );
};
