import { BlockDynamicPropertiesModel } from "@/feature/block/domain/model/BlockModel";
import { Combobox } from "@/infrastructure/uikit/components/ui/combobox";
import { Input } from "@/infrastructure/uikit/components/ui/input";
import { Label } from "@/infrastructure/uikit/components/ui/label";
import { ChevronDownSquare, SquarePen } from "lucide-react";
import React, { useEffect, useState } from "react";

type BlockComboboxPickerViewProps = {
  property: BlockDynamicPropertiesModel;
  value: string;
  onChange: (key: string, newValue: string) => void;
};

export const BlockComboboxPickerView = (props: BlockComboboxPickerViewProps) => {
  const [isManual, setIsManual] = useState(false);
  const [value, setValue] = useState(props.value);
  const options = JSON.parse(props.property.valuePickerOptions ?? "") ?? [];
  useEffect(() => {
    setValue(
      options?.find((opt: any) => {
        return opt?.id === props.value;
      })?.id ?? props.value
    );
  }, []);
  return (
    <>
      <div
        title={props.property.deprecatedReason ?? ""}
        key={props.property.key + Math.random().toString()}
        className="grid w-full items-center gap-1.5 bg-muted p-2 rounded-lg"
      >
        <Label
          className={props.property.deprecated ? `line-through` : ``}
          key={props.property.key + Math.random().toString()}
          title={props.property.description ?? ""}
          htmlFor={"blockComboboxPicker_" + props.property.key}
        >
          {props.property.key}
        </Label>
        <div className="relative flex items-center max-w-2xl">
          {isManual ? (
            <div className="flex flex-row justify-between w-full items-center gap-2">
              <Input
                autoComplete="off"
                key={props.property.key + Math.random().toString()}
                defaultValue={props.value}
                type="blockComboboxPicker_"
                id={"blockComboboxPicker_" + props.property.key}
                placeholder={props.property.description ?? ""}
                onChange={(event) => {
                  props.onChange(props.property.key ?? "", event.target.value);
                }}
              />
              <ChevronDownSquare
                className="h-4 w-4"
                onClick={() => {
                  setIsManual(!isManual);
                }}
              />
            </div>
          ) : (
            <div className="flex flex-row justify-between w-full items-center gap-2">
              <Combobox
                key={props.property.key + Math.random().toString()}
                list={options}
                defaultValue={value}
                onSelect={(id: string, _: string) => {
                  setValue(id);
                  props.onChange(props.property.key ?? "", id);
                }}
              />
              <SquarePen
                className="h-4 w-4"
                onClick={() => {
                  setIsManual(!isManual);
                }}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};
