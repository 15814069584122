import {
  IntegrationDataModel,
  IntegrationEventModel,
  IntegrationModel,
  IntegrationPropertyModel,
  IntegrationSlotModel,
} from "../domain/model/integrationModel";

export function integrationToModel(item: any): IntegrationModel {
  return {
    id: item.id ?? "",
    name: item.name,
    description: item.description ?? "",
    documentation: item.documentation ?? "",
    imageIcon: item.imageIcon ?? "",
    keyType: item.keyType ?? "",
    kind: item.kind ?? "",
    manageable: item.manageable ?? false,
    platformSupport: item.platformSupport ?? "",
    price: item.price ?? "",
    public: item.public ?? "",
    version: item.version ?? -1,
    deprecated: item.deprecated ?? false,
    deprecatedReason: item.deprecatedReason ?? "",
    properties:
      item.properties?.map((property: any) => {
        return integrationPropertyToModel(property);
      }) ?? [],
    events:
      item.events?.map((event: any) => {
        return integrationEventToModel(event);
      }) ?? [],
    data:
      item.data?.map((event: any) => {
        return integrationDataModelToModel(event);
      }) ?? [],
    slots:
      item.slots?.map((event: any) => {
        return integrationSlotToModel(event);
      }) ?? [],
  } as IntegrationModel;
}

function integrationPropertyToModel(item: any): IntegrationPropertyModel {
  return {
    id: item.id ?? "",
    key: item.key ?? "",
    value: item.value ?? "",
    type: item.type ?? "",
    description: item.description ?? "",
    valuePicker: item.valuePicker ?? "",
    valuePickerGroup: item.valuePickerGroup ?? "",
    valuePickerOptions: item.valuePickerOptions ?? "",
    deprecated: item.deprecated ?? false,
    deprecatedReason: item.deprecatedReason ?? "",
  } as IntegrationPropertyModel;
}

function integrationDataModelToModel(item: any): IntegrationDataModel {
  return {
    id: item.id ?? "",
    key: item.key ?? "",
    value: item.value ?? "",
    type: item.type ?? "",
    deprecated: item.deprecated ?? false,
    deprecatedReason: item.deprecatedReason ?? "",
  } as IntegrationDataModel;
}

function integrationEventToModel(item: any): IntegrationEventModel {
  return {
    id: item.id,
    event: item.event,
    deprecated: item.deprecated ?? false,
    deprecatedReason: item.deprecatedReason ?? "",
  } as IntegrationEventModel;
}

function integrationSlotToModel(item: any): IntegrationSlotModel {
  return {
    id: item.id,
    slot: item.slot,
    deprecated: item.deprecated ?? false,
    deprecatedReason: item.deprecatedReason ?? "",
  } as IntegrationSlotModel;
}
