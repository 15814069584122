import { Button } from "@/infrastructure/uikit/components/ui/button";
import { Card } from "@/infrastructure/uikit/components/ui/card";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/infrastructure/uikit/components/ui/dropdown-menu";
import { Input } from "@/infrastructure/uikit/components/ui/input";
import { Label } from "@/infrastructure/uikit/components/ui/label";
import NativeDialog from "@/infrastructure/uikit/components/ui/native-dialog";
import { ScrollArea } from "@/infrastructure/uikit/components/ui/scroll-area";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/infrastructure/uikit/components/ui/select";
import { ArrowUpCircle, Box, ChevronDown, ChevronRight, Copy, MoreVertical, Pencil, Plus, Trash2 } from "lucide-react";
import React, { FC, useEffect, useState } from "react";
import { NodeApi, Tree } from "react-arborist";
import { BlockIntegrationModel, BlockModel, BlockSlotModel } from "../domain/model/BlockModel";
import { BlockViewModel } from "./BlockViewModel";

export type BlockHierarchyViewProps = {
  frameId: string;
  blockViewModel: BlockViewModel;
  installedBlocks: BlockIntegrationModel[];
  onBlockSelect: () => void;
  onAddBlock: (key: string) => void;
  onDeleteBlock: (key: string) => void;
  onDuplicateBlock: (key: string) => void;
  onUpdateBlockKey: (key: string, newKey: string) => void;
};

export const BlockHierarchyView: FC<BlockHierarchyViewProps> = (props: BlockHierarchyViewProps) => {
  const [key, setKey] = useState("");
  const [slot, setSlot] = useState("");
  const [parentId, setParentId] = useState("");
  const [isAddBlockOpen, setIsAddBlockOpen] = useState(false);
  const [isSlotPickerOpen, setIsSlotPickerOpen] = useState(false);
  const [selectedIntegration, setSelectedIntegration] = useState<BlockIntegrationModel | null>(null);
  const [dragNode, setDragNode] = useState<NodeApi<BlockModel> | null>(null);
  const [dragData, setDragData] = useState<{
    dragIds: string[];
    dragNodes: NodeApi<BlockModel>[];
    parentId: string | null;
    parentNode: NodeApi<BlockModel> | null;
    index: number;
  } | null>(null);

  useEffect(() => {
    props.blockViewModel.uiAction.getBlocks(props.frameId);
  }, [props.frameId]);

  return (
    <>
      <div className="flex flex-col w-full">
        <div className="space-y-2">
          <NativeDialog
            isOpen={isAddBlockOpen}
            onClose={() => {
              setIsAddBlockOpen(false);
            }}
            panel={
              <div className="h-2/3 flex flex-col">
                <div>
                  <Label className="sr-only" htmlFor="key">
                    key
                  </Label>
                  <Input
                    id="key"
                    placeholder="Block unique key"
                    type="text"
                    autoCapitalize="none"
                    autoComplete="off"
                    autoCorrect="off"
                    onChange={(event: any) => setKey(event.target.value)}
                  />
                </div>
                <ScrollArea className="w-full pt-4 h-full">
                  <div className="grid grid-cols-2 gap-4">
                    {props.installedBlocks.map((integration: BlockIntegrationModel) => {
                      return (
                        <Card
                          key={integration.id}
                          className={`${integration.id === selectedIntegration?.id ? "border-muted-foreground" : ""}`}
                          onClick={() => {
                            setSelectedIntegration(integration);
                          }}
                        >
                          <div className={"flex flex-row p-2 justify-start items-center gap-4"}>
                            <Box className="h-8 w-8 my-4" />
                            {integration.name}
                          </div>
                        </Card>
                      );
                    })}
                  </div>
                </ScrollArea>
                <Button
                  onClick={() => {
                    props.blockViewModel.uiAction.addBlock(
                      props.frameId,
                      selectedIntegration?.id ?? "",
                      parentId,
                      key,
                      slot
                    );
                    setSelectedIntegration(null);
                    setKey("");
                    setSlot("");
                    setParentId("");
                    props.onAddBlock(key);
                    setIsAddBlockOpen(false);
                  }}
                  disabled={!key}
                  variant={"default"}
                  autoFocus
                  className="w-full"
                >
                  Add
                </Button>
              </div>
            }
          />
          <NativeDialog
            isOpen={isSlotPickerOpen}
            onClose={() => {
              setIsSlotPickerOpen(false);
            }}
            panel={
              <div className="h-auto flex flex-col gap-4">
                <p>Move block to slots of {dragData?.parentNode?.data.key}</p>
                <Select
                  onValueChange={(value: string) => {
                    setSlot(value);
                  }}
                >
                  <SelectTrigger className="w-full">
                    <SelectValue placeholder={"Select slot"} />
                  </SelectTrigger>
                  <SelectContent>
                    {Array((dragData?.parentNode?.data?.slots as any as BlockSlotModel[]) ?? []).map((items: any[]) => {
                      return items.map((slot: any) => {
                        return (
                          <SelectItem key={slot?.id ?? Math.random()} value={slot?.slot ?? ""}>
                            {slot?.slot ?? ""}
                          </SelectItem>
                        );
                      });
                    })}
                  </SelectContent>
                </Select>
                <Button
                  onClick={() => {
                    props.blockViewModel.uiAction.moveBlock(
                      props.frameId,
                      dragNode?.data.id ?? "",
                      dragData?.parentNode?.data.id ?? "",
                      dragData?.index ?? 0,
                      slot
                    );
                    setIsSlotPickerOpen(false);
                    setSlot("");
                    setParentId("");
                  }}
                  disabled={!slot}
                  variant={"default"}
                  autoFocus
                  className="w-full"
                >
                  Update position and slot
                </Button>
              </div>
            }
          />
          <Tree
            className="bg-background"
            rowHeight={40}
            indent={16}
            width={"w-full"}
            height={window.innerHeight - 130}
            onMove={(data) => {
              data.dragNodes.map((dragNodeItem) => {
                setDragNode(dragNodeItem);
                setDragData(data);
                setIsSlotPickerOpen(true);
              });
            }}
            childrenAccessor={(b) => b.subBlocks}
            data={props.blockViewModel.uiState.blocksHierarchy}
            disableMultiSelection={true}
            disableDrop={({ parentNode }) => {
              return !parentNode.data.isParent;
            }}
          >
            {(treeProps) => (
              <Node
                blockViewModel={props.blockViewModel}
                frameId={props.frameId}
                onBlockSelect={(frameId: string, blockId: string) => {
                  props.blockViewModel.uiAction.getBlock(frameId, blockId);
                  props.onBlockSelect();
                }}
                onAddBlock={(parentId: string, slot: string) => {
                  setIsAddBlockOpen(true);
                  setParentId(parentId);
                  setSlot(slot);
                }}
                onDeleteBlock={props.onDeleteBlock}
                integrationList={props.installedBlocks}
                onUpdateBlockKey={props.onUpdateBlockKey}
                onDuplicateBlock={props.onDuplicateBlock}
                {...treeProps}
              />
            )}
          </Tree>
        </div>
      </div>
    </>
  );
};

const Node = (props: any) => {
  const integration = props.integrationList.find((it: BlockIntegrationModel) => {
    return it.keyType === props.node.data.keyType;
  });
  return (
    <div
      key={props.node.data.id}
      style={props.style}
      ref={props.dragHandle as any}
      className={
        props.node.isSelected
          ? "bg-secondary items-center justify-between w-full flex flex-row rounded-md pe-2"
          : "items-center justify-between w-full flex flex-row pe-2"
      }
      title={props.node.data.keyType + " " + props.node.data.slot}
      onClick={() => {
        props.onBlockSelect(props.frameId, props.node.data.id ?? "");
      }}
    >
      {props.node.isEditing ? (
        <input
          type="text"
          autoComplete="off"
          defaultValue={props.node.data.name}
          onFocus={(e) => e.currentTarget.select()}
          onBlur={() => props.node.reset()}
          onKeyDown={(e) => {
            if (e.key === "Escape") props.node.reset();
            if (e.key === "Enter") {
              props.node.submit(e.currentTarget.value);
              props.blockViewModel.uiAction.updateBlockKey(
                props.frameId,
                props.node.data.id ?? "",
                e.currentTarget.value
              );
              props.onUpdateBlockKey(props.node.data.key, e.currentTarget.value);
            }
          }}
          autoFocus
        />
      ) : (
        <>
          <div className="flex flex-row w-full p-2">
            {props.node.data.isParent ? (
              props.node.isOpen ? (
                <ChevronDown onClick={() => props.node.isInternal && props.node.toggle()} className="h-4 w-4" />
              ) : (
                <ChevronRight onClick={() => props.node.isInternal && props.node.toggle()} className="h-4 w-4" />
              )
            ) : (
              <></>
            )}
            {(integration?.version ?? 0) > props.node.data.integrationVersion ? (
              <>
                <div className="h-1.5 w-1.5 rounded-full bg-destructive"></div>
              </>
            ) : (
              <></>
            )}
            <p className="text-sm px-2">{props.node.data.key}</p>
          </div>

          <DropdownMenu>
            <DropdownMenuTrigger>
              <MoreVertical className="h-4 w-4" />
            </DropdownMenuTrigger>
            <DropdownMenuContent>
              {props.node.data.isParent ? (
                props.node.data.slots.map((sl: BlockSlotModel) => {
                  return (
                    <DropdownMenuItem
                      key={sl.id}
                      onSelect={() => {
                        props.onAddBlock(props.node.data.id, sl.slot);
                      }}
                    >
                      <Plus className="w-4 h-4 mx-2" />
                      Add {sl.slot}
                    </DropdownMenuItem>
                  );
                })
              ) : (
                <></>
              )}
              {props.node.data.key === "root" ? (
                <></>
              ) : (
                <>
                  <DropdownMenuItem
                    onSelect={() => {
                      props.blockViewModel.uiAction.duplicateBlock(
                        props.frameId,
                        props.node.data.id ?? "",
                        props.node.data.key + "(Copy)"
                      );
                      props.onDuplicateBlock(props.node.data.key + "(Copy)");
                    }}
                  >
                    <Copy className="w-4 h-4 mx-2" />
                    Duplicate {props.node.data.key}
                  </DropdownMenuItem>

                  <DropdownMenuItem
                    onSelect={() => {
                      props.node.edit();
                    }}
                  >
                    <Pencil className="w-4 h-4 mx-2" />
                    Update key {props.node.data.key}
                  </DropdownMenuItem>
                  <DropdownMenuItem
                    onSelect={() => {
                      props.blockViewModel.uiAction.deleteBlock(props.frameId, props.node.data.id ?? "");
                      props.onDeleteBlock(props.node.data.key ?? "");
                    }}
                  >
                    <Trash2 className="w-4 h-4 mx-2" />
                    Delete {props.node.data.key}
                  </DropdownMenuItem>
                </>
              )}

              {(integration?.version ?? 0) > props.node.data.integrationVersion ? (
                <DropdownMenuItem
                  onSelect={() => {
                    props.blockViewModel.uiAction.upgradeBlock(
                      props.frameId,
                      props.node.data.id ?? "",
                      integration?.id
                    );
                  }}
                >
                  <ArrowUpCircle className="w-4 h-4 mx-2" />
                  Upgrade {props.node.data.key}
                </DropdownMenuItem>
              ) : (
                <></>
              )}
            </DropdownMenuContent>
          </DropdownMenu>
        </>
      )}
    </div>
  );
};
