import { Label } from "@/infrastructure/uikit/components/ui/label";
import { Textarea } from "@/infrastructure/uikit/components/ui/textarea";
import React from "react";
import { BlockDynamicPropertiesModel } from "@/feature/block/domain/model/BlockModel";

type BlockTextAreaPickerViewProps = {
  property: BlockDynamicPropertiesModel;
  value: string;
  onChange: (key: string, newValue: string) => void;
};

export const BlockTextAreaPickerView = (props: BlockTextAreaPickerViewProps) => {
  return (
    <>
      <div
        title={props.property.deprecatedReason ?? ""}
        key={props.property.key + Math.random().toString()}
        className="grid w-full items-center gap-1.5 bg-muted p-2 rounded-lg"
      >
        <Label
          className={props.property.deprecated ? `line-through` : ``}
          key={props.property.key + Math.random().toString()}
          title={props.property.description ?? ""}
          htmlFor={"blockTextAreaPicker_" + props.property.key}
        >
          {props.property.key}
        </Label>
        <Textarea
          className={props.property.deprecated ? `line-through` : ``}
          key={props.property.key + Math.random().toString()}
          defaultValue={props.value}
          id={"blockTextAreaPicker_" + props.property.key}
          placeholder={props.property.description ?? ""}
          onChange={(event) => {
            props.onChange(props.property.key ?? "", event.target.value);
          }}
        />
      </div>
    </>
  );
};
