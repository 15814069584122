import { ResultModel } from "@/infrastructure/result/model/ResultModel";
import { gql } from "@apollo/client";
import FrameRepository from "../../domain/FrameRepository";
import { FrameModel, FrameNavigationArgumentModel, ScaffoldModel } from "../../domain/model/FrameModel";

const GET_SCAFFOLD_QUERY = gql`
  query scaffold {
    scaffold {
      project {
        id
        name
        platform
        organization {
          id
        }
      }
      frames {
        id
        name
        route
        type
        isStarter
        routeArguments {
          name
        }
      }
    }
  }
`;

const ADD_FRAME_MUTATION = gql`
  mutation addFrame($input: AddFrameInput!) {
    addFrame(input: $input) {
      id
    }
  }
`;

const UPDATE_FRAME_MUTATION = gql`
  mutation updateFrame($input: UpdateFrameInput!) {
    updateFrame(input: $input) {
      id
    }
  }
`;

const DELETE_FRAME_MUTATION = gql`
  mutation deleteFrame($input: DeleteFrameInput!) {
    deleteFrame(input: $input) {
      id
    }
  }
`;

const PUBLISH_FRAME_MUTATION = gql`
  mutation publishFrame($input: PublishFrameInput!) {
    publishFrame(input: $input) {
      route
    }
  }
`;

export class FrameRepositoryImpl implements FrameRepository {
  private readonly graphqlClient: any;

  constructor(graphqlClient: any) {
    this.graphqlClient = graphqlClient;
  }

  async getScaffold(): Promise<ResultModel<ScaffoldModel>> {
    try {
      const result = await this.graphqlClient.query({
        query: GET_SCAFFOLD_QUERY,
      });

      const list: FrameModel[] = result?.data?.scaffold.frames?.map((item?: any) => {
        return this.frameMapToModel(item);
      });

      return <ResultModel<ScaffoldModel>>{
        onSuccess: {
          frames: list,
          projectName: result?.data?.scaffold.project.name,
          projectId: result?.data?.scaffold.project.id,
          projectPlatform: result?.data?.scaffold.project.platform,
          organizationId: result?.data?.scaffold.project.organization.id,
        },
      };
    } catch (error: any) {
      return <ResultModel<ScaffoldModel>>{
        onError: error.message,
      };
    }
  }

  async addFrame(name: string, route: string, type: string, isStarter: boolean): Promise<ResultModel<any>> {
    try {
      await this.graphqlClient.mutate({
        mutation: ADD_FRAME_MUTATION,
        variables: {
          input: {
            name: name,
            route: route,
            type: type,
            isStarter: isStarter,
            routeArguments: this.getWordsBetweenCurly(route).map((arg) => {
              return { name: arg };
            }),
          },
        },
      });
      return <ResultModel<any>>{
        onSuccess: {},
      };
    } catch (error: any) {
      return <ResultModel<any>>{
        onError: error.message,
      };
    }
  }

  async updateFrame(
    id: string,
    name: string,
    route: string,
    type: string,
    isStarter: boolean
  ): Promise<ResultModel<any>> {
    try {
      await this.graphqlClient.mutate({
        mutation: UPDATE_FRAME_MUTATION,
        variables: {
          input: {
            id: id,
            name: name,
            route: route,
            type: type,
            isStarter: isStarter,
            routeArguments: this.getWordsBetweenCurly(route).map((arg) => {
              return { name: arg };
            }),
          },
        },
      });
      return <ResultModel<any>>{
        onSuccess: {},
      };
    } catch (error: any) {
      return <ResultModel<any>>{
        onError: error.message,
      };
    }
  }

  async deleteFrame(route: string): Promise<ResultModel<any>> {
    try {
      await this.graphqlClient.mutate({
        mutation: DELETE_FRAME_MUTATION,
        variables: {
          input: {
            route: route,
          },
        },
      });
      return <ResultModel<any>>{
        onSuccess: {},
      };
    } catch (error: any) {
      return <ResultModel<any>>{
        onError: error.message,
      };
    }
  }

  async publishFrame(route: string): Promise<ResultModel<any>> {
    try {
      await this.graphqlClient.mutate({
        mutation: PUBLISH_FRAME_MUTATION,
        variables: {
          input: {
            route: route,
          },
        },
      });
      return <ResultModel<any>>{
        onSuccess: {},
      };
    } catch (error: any) {
      return <ResultModel<any>>{
        onError: error.message,
      };
    }
  }

  private frameMapToModel(item: any): FrameModel {
    return {
      id: item?.id ?? "",
      name: item?.name ?? "",
      route: item?.route ?? "",
      isStarter: item?.isStarter ?? "",
      type: item?.type ?? "",
      routeArguments:
        item?.routeArguments?.map((arg?: any) => {
          <FrameNavigationArgumentModel>{
            name: arg?.name ?? "",
          };
        }) ?? [],
    };
  }

  private getWordsBetweenCurly(text: string): string[] {
    const regex = /\{(.*?)\}/g;
    const matches = Array.from(text.matchAll(regex));

    const result: string[] = [];
    for (const match of matches) {
      if (match[1]) {
        result.push(match[1]);
      }
    }
    return result;
  }
}
