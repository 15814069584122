import {
  ADD_ACTION_MUTATION,
  ADD_ACTION_TRIGGER_MUTATION,
  DELETE_ACTION_MUTATION,
  DELETE_ACTION_TRIGGER_MUTATION,
  GET_ACTION_TRIGGER_QUERY,
  GET_ACTIONS_QUERY,
  UPDATE_ACTION_TRIGGER_NAME_MUTATION,
  UPDATE_ACTION_TRIGGER_POSITION_MUTATION,
  UPDATE_ACTION_TRIGGER_PROPERTIES_MUTATION,
  UPGRADE_ACTION_MUTATION,
} from "@/feature/action/data/ActionQuery";
import { ActionRepository } from "@/feature/action/domain/repository/ActionRepository";
import { ResultModel } from "@/infrastructure/result/model/ResultModel";
import { ActionModel, ActionTriggerEventModel, ActionTriggerModel } from "../domain/model/ActionModel";

export class ActionRepositoryImpl implements ActionRepository {
  private readonly graphqlClient: any;

  constructor(graphqlClient: any) {
    this.graphqlClient = graphqlClient;
  }

  async getActions(frameId: string): Promise<ResultModel<ActionModel[]>> {
    try {
      const result = await this.graphqlClient.query({
        query: GET_ACTIONS_QUERY,
        variables: { frameId: frameId },
      });

      const list: ActionModel[] = result?.data?.actions?.map((item?: any) => {
        return this.actionToModel(item);
      });

      return <ResultModel<ActionModel[]>>{
        onSuccess: list,
      };
    } catch (error: any) {
      return <ResultModel<ActionModel[]>>{
        onError: error.message,
      };
    }
  }

  async addAction(frameId: string, key: string, event: string): Promise<ResultModel<any>> {
    try {
      await this.graphqlClient.query({
        query: ADD_ACTION_MUTATION,
        variables: {
          input: {
            frameId: frameId,
            key: key,
            event: event,
          },
        },
      });

      return <ResultModel<any>>{
        onSuccess: {},
      };
    } catch (error: any) {
      return <ResultModel<any>>{
        onError: error.message,
      };
    }
  }

  async addActionTrigger(
    frameId: string,
    actionId: string,
    integrationId: string,
    name: string,
    parentId: string | null,
    then: string
  ): Promise<ResultModel<any>> {
    try {
      await this.graphqlClient.query({
        query: ADD_ACTION_TRIGGER_MUTATION,
        variables: {
          input: {
            frameId: frameId,
            actionId: actionId,
            integrationId: integrationId,
            name: name,
            parentId: parentId,
            then: then,
          },
        },
      });

      return <ResultModel<any>>{
        onSuccess: {},
      };
    } catch (error: any) {
      return <ResultModel<any>>{
        onError: error.message,
      };
    }
  }

  async deleteAction(frameId: string, actionId: string): Promise<ResultModel<any>> {
    try {
      await this.graphqlClient.query({
        query: DELETE_ACTION_MUTATION,
        variables: {
          input: {
            frameId: frameId,
            actionId: actionId,
          },
        },
      });

      return <ResultModel<any>>{
        onSuccess: {},
      };
    } catch (error: any) {
      return <ResultModel<any>>{
        onError: error.message,
      };
    }
  }

  async deleteActionTrigger(frameId: string, actionId: string, triggerId: string): Promise<ResultModel<any>> {
    try {
      await this.graphqlClient.query({
        query: DELETE_ACTION_TRIGGER_MUTATION,
        variables: {
          input: {
            frameId: frameId,
            actionId: actionId,
            actionTriggerId: triggerId,
          },
        },
      });

      return <ResultModel<any>>{
        onSuccess: {},
      };
    } catch (error: any) {
      return <ResultModel<any>>{
        onError: error.message,
      };
    }
  }

  async updateActionTriggerProperties(
    frameId: string,
    actionId: string,
    triggerId: string,
    changeMap: Map<string, string>
  ): Promise<ResultModel<any>> {
    try {
      await this.graphqlClient.mutate({
        mutation: UPDATE_ACTION_TRIGGER_PROPERTIES_MUTATION,
        variables: {
          input: {
            frameId: frameId,
            actionId: actionId,
            actionTriggerId: triggerId,
            properties: Array.from(changeMap.keys()).map((key) => {
              return {
                key: key,
                value: changeMap.get(key),
              };
            }),
          },
        },
      });
      return <ResultModel<any>>{
        onSuccess: {},
      };
    } catch (error: any) {
      return <ResultModel<any>>{
        onError: error.message,
      };
    }
  }

  async getActionTrigger(frameId: string, actionId: string, triggerId: string): Promise<ResultModel<ActionTriggerModel>> {
    try {
      const result = await this.graphqlClient.query({
        query: GET_ACTION_TRIGGER_QUERY,
        variables: { frameId: frameId, actionId: actionId, actionTriggerId: triggerId },
      });

      return <ResultModel<ActionTriggerModel>>{
        onSuccess: this.actionTriggerModel(result?.data.actionTriggerById),
      };
    } catch (error: any) {
      return <ResultModel<ActionTriggerModel>>{
        onError: error.message,
      };
    }
  }

  async upgradeActionTrigger(
    frameId: string,
    actionId: string,
    actionTriggerId: string,
    integrationId: string
  ): Promise<ResultModel<any>> {
    try {
      await this.graphqlClient.mutate({
        mutation: UPGRADE_ACTION_MUTATION,
        variables: {
          input: {
            frameId: frameId,
            actionId: actionId,
            actionTriggerId: actionTriggerId,
            integrationId: integrationId,
          },
        },
      });
      return <ResultModel<any>>{
        onSuccess: {},
      };
    } catch (error: any) {
      return <ResultModel<any>>{
        onError: error.message,
      };
    }
  }

  async updateActionTriggerPosition(
    frameId: string,
    actionId: string,
    actionTriggerId: string,
    parentId: string,
    then: string
  ): Promise<ResultModel<any>> {
    try {
      await this.graphqlClient.mutate({
        mutation: UPDATE_ACTION_TRIGGER_POSITION_MUTATION,
        variables: {
          input: {
            frameId: frameId,
            actionId: actionId,
            actionTriggerId: actionTriggerId,
            parentId: parentId,
            then: then,
          },
        },
      });
      return <ResultModel<any>>{
        onSuccess: {},
      };
    } catch (error: any) {
      return <ResultModel<any>>{
        onError: error.message,
      };
    }
  }

  async updateActionTriggerName(
    frameId: string,
    actionId: string,
    actionTriggerId: string,
    name: string
  ): Promise<ResultModel<any>> {
    try {
      await this.graphqlClient.mutate({
        mutation: UPDATE_ACTION_TRIGGER_NAME_MUTATION,
        variables: {
          input: {
            frameId: frameId,
            actionId: actionId,
            actionTriggerId: actionTriggerId,
            name: name,
          },
        },
      });
      return <ResultModel<any>>{
        onSuccess: {},
      };
    } catch (error: any) {
      return <ResultModel<any>>{
        onError: error.message,
      };
    }
  }

  actionToModel(item: any): ActionModel {
    return {
      id: item.id,
      key: item.key,
      event: item.event,
      triggers: item.triggers
        .sort((a: any, b: any) => {
          if (a.then < b.then) {
            return -1;
          }
          if (a.then > b.then) {
            return 1;
          }
          return 0;
        })
        .map((trigger: any) => {
          return this.actionTriggerModel(trigger);
        }),
    } as ActionModel;
  }

  actionTriggerModel(trigger: any): ActionTriggerModel {
    return {
      id: trigger?.id,
      parentId: trigger?.parentId,
      keyType: trigger?.keyType,
      name: trigger?.name,
      then: trigger?.then,
      integrationVersion: trigger?.integrationVersion,
      events: trigger?.events?.map((event: any) => {
        return {
          id: event?.id,
          event: event?.event,
          deprecated: event.deprecated,
          deprecatedReason: event.deprecatedReason,
        } as ActionTriggerEventModel;
      }),
      properties: new Map(
        trigger?.properties
          ?.sort((a: any, b: any) => {
            if (a.valuePickerGroup > b.valuePickerGroup) {
              return -1;
            }
            if (a.valuePickerGroup < b.valuePickerGroup) {
              return 1;
            }
            return 0;
          })
          .map((property: any) => {
            return [
              property.key,
              {
                key: property.key,
                value: property.value,
                type: property.type,
                deprecated: property.deprecated,
                deprecatedReason: property.deprecatedReason,
                valuePicker: property.valuePicker,
                valuePickerGroup: property.valuePickerGroup,
                valuePickerOptions: property.valuePickerOptions,
              },
            ];
          })
      ),
    } as ActionTriggerModel;
  }
}
