import { BlockViewModel } from "@/feature/block/presentation/BlockViewModel";
import { FrameViewModel } from "@/feature/frame/presentation/FrameViewModel";
import { IntegrationViewModel } from "@/feature/integration/presentation/IntegrationViewModel";
import { LocalizationViewModel } from "@/feature/localization/presentation/LocalizationViewModel";
import { ActionViewModel } from "@/feature/action/presentation/ActionViewModel";
import { VariableViewModel } from "@/feature/variable/presentation/VariableViewModel";
import React, { createContext, useContext } from "react";
import { WorkspaceViewModel } from "../WorkspaceViewModel";
import { PreviewViewModel } from "../preview/PreviewViewModel";

export interface WorkspaceState {
  workspaceViewModel: WorkspaceViewModel;
  frameViewModel: FrameViewModel;
  blockViewModel: BlockViewModel;
  variableViewModel: VariableViewModel;
  integrationViewModel: IntegrationViewModel;
  actionViewModel: ActionViewModel;
  localizationViewModel: LocalizationViewModel;
  previewViewModel:PreviewViewModel;
}

const WorkspaceStateContext = createContext<WorkspaceState | undefined>(undefined);

export const useWorkspaceState = (): WorkspaceState => {
  const context = useContext(WorkspaceStateContext);
  if (!context) {
    throw new Error("useWorkspaceState must be used within a WorkspaceStateProvider");
  }
  return context;
};

export const WorkspaceStateProvider: React.FC<any> = (props: any) => {
  return <WorkspaceStateContext.Provider value={props.value}>{props.children}</WorkspaceStateContext.Provider>;
};
