import {ApolloClient, createHttpLink, DefaultOptions, from, InMemoryCache,} from "@apollo/client";
import {onError} from "@apollo/client/link/error";
import {setContext} from "@apollo/client/link/context";
import {AppEnvironmentConfig} from "@/infrastructure/network/EnvironmentProvider";

const httpLink = createHttpLink({
  uri: AppEnvironmentConfig.API_ENDPOINT_GRAPHQL_URL,
  headers: {
    "Content-Type": "application/json",
    "access-Control-allow-origin": "*",
    "access-Control-allow-credentials": "true",
  },
});

const authLink = setContext((_, {headers}) => {
  const token = localStorage.getItem("authAccessToken");
  const apiKey = localStorage.getItem("apiKey");
  return {
    headers: {
      ...headers,
      "Authorization": token ? `Bearer ${token}` : "",
      "Api-Key": apiKey ? `Bearer ${apiKey}` : "",
    },
  };
});

const errorLink = onError(({graphQLErrors, networkError}) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({message, locations, path, extensions}) => {
      // AUTHORIZATION_EXPIRED
      if (extensions?.classification === "UNAUTHORIZED") {
        localStorage.removeItem("authAccessToken");
        localStorage.removeItem("authRefreshToken");
      }
    });
  }
});

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: "no-cache",
    errorPolicy: "ignore",
  },
  query: {
    fetchPolicy: "no-cache",
    errorPolicy: "all",
  },
};

export const graphqlClient = new ApolloClient({
  link: from([errorLink, authLink.concat(httpLink)]),
  cache: new InMemoryCache(),
  defaultOptions: defaultOptions,
});
