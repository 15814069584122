import { ResultModel } from "@/infrastructure/result/model/ResultModel";
import { gql } from "@apollo/client";
import { AuthRepository } from "../../domain/AuthRepository";
import { AuthUserModel } from "../../domain/AuthUserModel";

const AUTH_LOGIN_MUTATION = gql`
  mutation authLogin($email: String!, $password: String!) {
    authLogin(email: $email, password: $password) {
      id
      username
      email
      accessToken
      refreshToken
    }
  }
`;

const AUTH_LOGIN_WITH_GOOGLE_MUTATION = gql`
  mutation authLoginWithGoogle($idToken: String!) {
    authLoginWithGoogle(idToken: $idToken) {
      id
      username
      email
      accessToken
      refreshToken
    }
  }
`;

export class AuthRepositoryImpl implements AuthRepository {
  private readonly graphqlClient: any;

  constructor(graphqlClient: any) {
    this.graphqlClient = graphqlClient;
  }

  async authLogin(email: string, password: string): Promise<ResultModel<AuthUserModel>> {
    try {
      const result = await this.graphqlClient.mutate({
        mutation: AUTH_LOGIN_MUTATION,
        variables: { email: email, password: password },
      });
      if (result.data.authLogin.accessToken && result.data.authLogin.refreshToken) {
        localStorage.setItem("username", result.data.authLogin.username);
        localStorage.setItem("authAccessToken", result.data.authLogin.accessToken);
        localStorage.setItem("authRefreshToken", result.data.authLogin.refreshToken);
      }
      return <ResultModel<AuthUserModel>>{
        onSuccess: {
          id: result.data.authLogin.id ?? "",
          username: result.data.authLogin.username ?? "",
          email: result.data.authLogin.email ?? "",
          accessToken: result.data.authLogin.accessToken ?? "",
          refreshToken: result.data.authLogin.refreshToken ?? "",
        },
      };
    } catch (error: any) {
      return <ResultModel<AuthUserModel>>{
        onError: error.message,
      };
    }
  }

  async authLoginWithGoogle(idToken: string): Promise<ResultModel<AuthUserModel>> {
    try {
      const result = await this.graphqlClient.mutate({
        mutation: AUTH_LOGIN_WITH_GOOGLE_MUTATION,
        variables: { idToken: idToken },
      });
      if (result.data.authLoginWithGoogle.accessToken && result.data.authLoginWithGoogle.accessToken) {
        localStorage.setItem("username", result.data.authLogin.username);
        localStorage.setItem("authAccessToken", result.data.authLoginWithGoogle.accessToken);
        localStorage.setItem("authRefreshToken", result.data.authLoginWithGoogle.refreshToken);
      }
      return <ResultModel<AuthUserModel>>{
        onSuccess: {
          id: result.data.authLoginWithGoogle.id ?? "",
          username: result.data.authLoginWithGoogle.username ?? "",
          email: result.data.authLoginWithGoogle.email ?? "",
          accessToken: result.data.authLoginWithGoogle.accessToken ?? "",
          refreshToken: result.data.authLoginWithGoogle.refreshToken ?? "",
        },
      };
    } catch (error: any) {
      return <ResultModel<AuthUserModel>>{
        onError: error.message,
      };
    }
  }

  getAuthToken = () => localStorage.getItem("authAccessToken") ?? "";
}
