import { BlockDynamicPropertiesModel } from "@/feature/block/domain/model/BlockModel";
import { Label } from "@/infrastructure/uikit/components/ui/label";
import { Switch } from "@/infrastructure/uikit/components/ui/switch";
import React from "react";

type BlockTogglePickerViewProps = {
  property: BlockDynamicPropertiesModel;
  value: string;
  onChange: (key: string, newValue: string) => void;
};

export const BlockTogglePickerView = (props: BlockTogglePickerViewProps) => {
  const boolValue = props.value.toLowerCase() === "true";
  return (
    <>
      <div
        title={props.property.deprecatedReason ?? ""}
        key={props.property.key + Math.random().toString()}
        className="grid w-full items-center gap-1.5 bg-muted px-2 py-4 rounded-lg"
      >
        <div className="flex items-center justify-between">
          <Label
            className={props.property.deprecated ? `line-through` : ``}
            key={props.property.key + Math.random().toString()}
            title={props.property.description ?? ""}
            htmlFor={"BlockTogglePicker_" + props.property.key}
          >
            {props.property.key}
          </Label>
          <Switch
            defaultChecked={boolValue}
            onCheckedChange={(value: any) => {
              props.onChange(props.property.key ?? "", value.toString());
            }}
            id={"BlockTogglePicker_" + props.property.key}
          />
        </div>
      </div>
    </>
  );
};
