import { authRepository } from "@/feature/auth/login/di/AuthComponent";
import { AppEnvironmentConfig } from "@/infrastructure/network/EnvironmentProvider";
import AgoraRTC, { IAgoraRTCClient, IAgoraRTCRemoteUser } from "agora-rtc-react";
import { useEffect, useState } from "react";
import useWebSocket, { ReadyState } from "react-use-websocket";
export type PreviewViewModel = {
  uiState: PreviewUiState;
  uiAction: PreviewUiAction;
};

type PreviewUiState = {
  authToken: string | null;
  platform: string | null;
  device: string | null;
  previewUrl: string | null;
  projectId: string;
  reconnectAttempts: number;
  targets: TargetConfigModel[];
  selectedTarget: TargetConfigModel;
  streamingChannel: string;
  streamingToken: string;
  streamingId: string;
  remoteUsers: IAgoraRTCRemoteUser[];
};

type PreviewUiAction = {
  handleHotReload: (frameRoute: string) => void;
  handleDeviceChange: (device: string) => void;
  selectTarget: (uuid: string) => void;
  setProjectPlatform: (projectId: string, platform: string) => void;
  setDevice: (device: string) => void;
  handleAgoraClientChange: (client: IAgoraRTCClient) => void;
  handleRemoteUsersChange: (users: IAgoraRTCRemoteUser[]) => void;
};

type TargetConfigModel = {
  uuid: string;
  videoHeight: number;
  videoWidth: number;
  deviceName: string;
};

interface SignalingModel {
  type: SignalingType;
  userType: SignalingUserType;
  username: string;
  projectId: string;
  uuid: string;
  target?: string;
  streamingChannel?: string;
  streamingToken?: string;
  streamingId?: string;
  availableTargets?: [TargetConfigModel];
  videoHeight?: number;
  videoWidth?: number;
  deviceName?: string;
  frameRoute?: string;
}

enum SignalingUserType {
  Studio = "Studio",
  SDK = "SDK",
}

enum SignalingType {
  Register = "Register",
  StartSession = "StartSession",
  Broadcasting = "Broadcasting",
  EndSession = "EndSession",
  AvailableTargets = "AvailableTargets",
  SelectedTarget = "SelectedTarget",
  UpdateSession = "UpdateSession",
  HotReload = "HotReload",
}

export const usePreviewViewModel = () => {
  // 4 is for disabling logs
  AgoraRTC.setLogLevel(4);

  const [socketConnect, setSocketConnect] = useState(false);
  let agoraClient: IAgoraRTCClient | null = null;
  const emptyTarget = {
    uuid: "",
    videoHeight: 640,
    videoWidth: 360,
    deviceName: "Not Selected",
  } as TargetConfigModel;

  const [uiState, setUiState] = useState<PreviewUiState>({
    authToken: authRepository.getAuthToken(),
    platform: null,
    device: "MOBILE",
    reconnectAttempts: 0,
    projectId: "",
    targets: [],
    selectedTarget: emptyTarget,
    streamingChannel: "",
    streamingToken: "",
    streamingId: "",
    remoteUsers: [],
    previewUrl: null,
  });

  const username = localStorage.getItem("username") || "";
  const uuid = "studio";

  const socket = useWebSocket(
    AppEnvironmentConfig.API_REALTIME_ENDPOINT_URL,
    {
      shouldReconnect: () => true,
      reconnectAttempts: 100,
      reconnectInterval: 5000,
      retryOnError: true,
      heartbeat: false,
    },
    socketConnect
  );

  useEffect(() => {
    if (socket.readyState == ReadyState.OPEN) {
      setUiState((prev) => ({
        ...prev,
        reconnectAttempts: 0,
      }));

      if (uiState.projectId) {
        sendMessage({
          type: SignalingType.Register,
          userType: SignalingUserType.Studio,
          username: username,
          projectId: uiState.projectId,
          uuid: uuid,
        });
      }
    }

    if (socket.readyState == ReadyState.CLOSED) {
      setUiState((prev) => ({
        ...prev,
        reconnectAttempts: prev.reconnectAttempts + 1,
      }));
    }
  }, [socket.readyState, uiState.projectId]);

  async function changeTarget(selectedTarget: TargetConfigModel) {
    if (selectedTarget.uuid != "") {
      setUiState((prev) => ({
        ...prev,
        selectedTarget: selectedTarget,
      }));

      await agoraClient
        ?.massUnsubscribe(
          uiState.remoteUsers.map((it) => {
            return {
              user: it,
              mediaType: "video",
            };
          }) ?? []
        )
        .catch((reason: any) => {
          console.log(`PreviewViewLog massUnsubscribe error :${JSON.stringify(reason)}`);
        });

      await agoraClient?.leave();

      sendMessage({
        type: SignalingType.SelectedTarget,
        userType: SignalingUserType.Studio,
        username: username,
        projectId: uiState.projectId,
        uuid: uuid,
        target: selectedTarget.uuid,
      });
    }
  }

  async function updateTargets(availableTargets: TargetConfigModel[]) {
    if (uiState.selectedTarget.uuid == "" && availableTargets.length > 0) {
      setUiState((prev) => ({
        ...prev,
        targets: availableTargets,
      }));
      changeTarget(availableTargets[0]);
    } else if (availableTargets.length == 0) {
      setUiState((prev) => ({
        ...prev,
        targets: availableTargets,
      }));

      changeTarget(emptyTarget);
    } else {
      setUiState((prev) => ({
        ...prev,
        targets: availableTargets,
      }));
      const target = uiState.targets.find((t) => t.uuid == uiState.selectedTarget.uuid);
      if (target) {
        changeTarget(target);
      } else {
        changeTarget(availableTargets[0]);
      }
    }
  }

  useEffect(() => {
    if (socket.lastMessage !== null) {
      const lastSignal = JSON.parse(socket.lastMessage?.data) as SignalingModel;
      switch (lastSignal.type) {
        case SignalingType.AvailableTargets:
          updateTargets(lastSignal.availableTargets || []);
          break;
        case SignalingType.StartSession:
          join(lastSignal.streamingChannel || "", lastSignal.streamingToken || "", lastSignal.streamingId || "");
          break;

        case SignalingType.UpdateSession:
          if (lastSignal.uuid == uiState.selectedTarget.uuid) {
            setUiState((prev) => ({
              ...prev,
              selectedTarget: {
                uuid: lastSignal.uuid,
                videoHeight: lastSignal.videoHeight || emptyTarget.videoHeight,
                videoWidth: lastSignal.videoWidth || emptyTarget.videoWidth,
                deviceName: lastSignal.deviceName || emptyTarget.deviceName,
              } as TargetConfigModel,
            }));
          }
          break;
        case SignalingType.EndSession:
          agoraClient?.leave();
          break;
        default:
          break;
      }
    }
  }, [socket.lastMessage]);

  async function join(channel: string, token: string, uid: string) {
    await agoraClient?.leave();

    await agoraClient
      ?.join(AppEnvironmentConfig.AGORA_APP_ID, channel, token, uid)
      .then(() => {
        setUiState((prev) => ({
          ...prev,
          streamingChannel: channel,
          streamingToken: token,
          streamingId: uid,
        }));
      })
      .catch((reason: any) => {
        console.log(`PreviewViewLog join error :${JSON.stringify(reason)}`);
      });
  }

  const sendMessage = (message: SignalingModel) => {
    socket.sendMessage(JSON.stringify(message));
  };

  const setDevice = (device: string) => {
    setUiState((prev) => ({
      ...prev,
      device: device,
    }));
  };

  const setProjectPlatform = (projectId: string, platform: string) => {
    setUiState((prev) => ({
      ...prev,
      projectId: projectId,
      platform: platform,
      previewUrl: localStorage.getItem(`PREVIEW_URL_${projectId}`) ?? null,
    }));

    if (socket.readyState == ReadyState.OPEN) {
      setUiState((prev) => ({
        ...prev,
        reconnectAttempts: 0,
      }));

      if (uiState.selectedTarget.uuid != "") {
        changeTarget(uiState.selectedTarget);
      }
    }
    setSocketConnect(true);
  };

  const handleDeviceChange = (device: string) => {
    setUiState((prev) => ({ ...prev, device }));
  };

  const handleRemoteUsersChange = (users: IAgoraRTCRemoteUser[]) => {
    setUiState((prev) => ({ ...prev, remoteUsers: users }));
  };

  const handleAgoraClientChange = (client: IAgoraRTCClient) => {
    agoraClient = client;
  };

  const selectTarget = (uuid: string) => {
    const target = uiState.targets.find((t) => t.uuid === uuid);
    if (target) {
      changeTarget(target);
    }
  };

  const handleHotReload = (frameRoute: string) => {
    sendMessage({
      type: SignalingType.HotReload,
      userType: SignalingUserType.Studio,
      username: username,
      projectId: uiState.projectId,
      uuid: uuid,
      target: uiState.selectedTarget.uuid,
      frameRoute: frameRoute,
    });
  };

  return {
    uiState,
    uiAction: {
      handleDeviceChange,
      selectTarget,
      setProjectPlatform,
      setDevice,
      handleAgoraClientChange,
      handleRemoteUsersChange,
      handleHotReload,
    },
  } as PreviewViewModel;
};
